<template>
  <div class="v_task_revoked g-flex-column">
    <head-back>
      <template v-slot:returnText>
          <span>返回</span>
        </template>
      <template>
        <span>{{titleName}}</span>
      </template>
    </head-back>

    <div class="v-task-revoked-container">
      <van-list class="v-task-revoked-list" v-show="orderList.length != 0" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
        <div class="v-task-revoked-list-item g-flex-align-center" v-for="(item, index) in orderList" :key="index">
          <div class="v-task-revoked-list-item-left">
            <img :src="item.img[0]" alt="">
          </div>
          <div class="v-task-revoked-list-item-right g-flex-column">
            <div class="v-task-revoked-item-right-top g-flex-align-center">
              <p class="v-task-revoked-item-right-top-title">宝贝标题：{{item.title | filtersStoreName}}</p>
              <span class="v-task-revoked-item-right-top-status" v-show="item.status == 0">已取消</span>
            </div>
            <div class="v-task-revoked-item-right-middle g-flex-align-center">
              <span class="v-task-revoked-item-right-middle-money">佣金：{{item.buy_price}} 元</span>
            </div>
            <!-- <div class="v-task-revoked-item-right-middle g-flex-align-center">
              <span class="v-task-revoked-item-right-middle-money">件数：{{item.buy_num}}件</span>
              <span class="v-task-revoked-item-right-middle-time">{{formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
            </div> -->
            <div class="v-task-revoked-item-right-bottom g-flex-justify-end">
              <span class="v-task-revoked-item-right-bottom-btn" @click="operaTask(item, 2)">查看详情</span>
            </div>
          </div>
        </div>
      </van-list>

       <NoList v-show="orderList.length == 0"/> 
      <!-- 任务详情 -->
      <task-details ref="TaskDetails" @emitSaveSuccess="emitSaveSuccess"></task-details>
    </div>
  </div>
</template>

<script>
  import HeadBack from '@/components/HeadBack.vue'
  import { formatDate } from '@/utils/index.js'
  import { PAGE, LIMIT } from '@/utils/constants.js'
  import { apiGetOrderList } from '@/utils/api.js'
  import TaskDetails from '@/views/taskliulan/taskdetails/TaskDetails.vue'
  export default {
    components: { HeadBack, TaskDetails },
    data() {
      return {
        routeType: this.$route.params.type,
        num: 10,
        form: {
          type: this.$route.params.type,
          status: '5', //all全部 1待操作 2待返款 3待收货 已完成4 已取消5
          page: PAGE,
          limit: LIMIT,
        },
        loading: false,
        finished: false,
        orderList: []
      }
    },
    created() {
      this.apiGetOrderListHandel()
      console.log(this.$route.params)
    },
    methods: {
      formatDate,
      emitSaveSuccess() {},
      // 获取任务列表
      async apiGetOrderListHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetOrderList(this.form)
        if(!success) return
        this.orderList = this.orderList.concat(data.list)
        this.loading = false
        this.finished = data.list.length < this.form.limit
      },
      onLoad() {
        this.form.page++
        this.apiGetOrderListHandel()
      },
      // 操作任务 点击查看详情 status 1 操作任务  2查看详情
      operaTask(item, status) {
        console.log('操作任务')
        this.$refs.TaskDetails.onShow(item, status)
      },
    },

    computed: {
      titleName() {
        if(this.routeType == '0') return '已撤销垫付任务'
        if(this.routeType == '1') return '已撤销浏览任务'
        return '已撤销预售任务'
      }
    }
  }
</script>

<style lang="scss">
.v_task_revoked {
  height: 100%;
  background: #F1F1F1;
  .v-task-revoked-container {
    flex: 1;
    overflow: auto;
    padding: 0 16px;
    position: relative;
    .v-task-revoked-list {
      .v-task-revoked-list-item {
        margin-top: 10px;
        padding: 10px 16px;
        background: $white;
        border-radius: 8px;
        .v-task-revoked-list-item-left {
          img {
            width: 80px;
            height: 80px;
            border-radius: 6px;
            object-fit: cover;
          }
        }

        .v-task-revoked-list-item-right {
          padding-left: 18px;
          flex: 1;
          .v-task-revoked-item-right-top {
            font-size: 14px;
            padding-top: 6px;
            .v-task-revoked-item-right-top-title {
              color: #040404;
              flex: 1;
              @include textManyOverflow()
            }
            .v-task-revoked-item-right-top-status {
              color: #FA436A;
              padding-left: 10px;
            }
          }

          .v-task-revoked-item-right-middle {
            font-size: 12px;
            color: #9E9D9D;
            padding-top: 14px;
            .v-task-revoked-item-right-middle-time {
              flex: 1;
              padding-left: 10px;
              text-align: right;
            }
          }

          .v-task-revoked-item-right-bottom {
            margin-top: 14px;
            .v-task-revoked-item-right-bottom-btn {
              font-size: 12px;
              color: $white;
              padding: 8px 10px;
              border-radius: 5px;
              background: $main_color;
            }
          }
        }
      }
    }
  }
}
</style>